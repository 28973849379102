import React, { createContext, ReactNode, SetStateAction, useState } from 'react';

import { OnboardingSteps } from '@/models/Enums';

type OnboardingContextModel = {
  onboardingStep: OnboardingSteps;
  onboardingDirection?: 'back' | 'next';
  setOnboardingStep: (value: SetStateAction<OnboardingSteps>) => void;
  setOnboardingDirection?: (value: SetStateAction<'back' | 'next'>) => void;
};

export const OnboardingContext = createContext<OnboardingContextModel | undefined>(undefined);

const OnboardingProvider = ({ children, postSignUp }: { children: ReactNode; postSignUp?: boolean }) => {
  const [onboardingStep, setOnboardingStep] = useState<OnboardingSteps>(
    postSignUp ? OnboardingSteps.USER : OnboardingSteps.PRE_SCREEN
  );
  const [onboardingDirection, setOnboardingDirection] = useState<'back' | 'next'>('next');

  const contextValue: OnboardingContextModel = {
    onboardingStep: onboardingStep,
    onboardingDirection: onboardingDirection,
    setOnboardingStep: setOnboardingStep,
    setOnboardingDirection: setOnboardingDirection,
  };

  return <OnboardingContext.Provider value={contextValue}>{children}</OnboardingContext.Provider>;
};

export default OnboardingProvider;
