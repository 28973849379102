import React, { createContext, ReactNode, useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import axios, { AxiosResponse, isAxiosError } from 'axios';
import { signOut } from 'firebase/auth';
import { KeyedMutator } from 'swr';

import { AllowedUnverifiedEmails } from '@/constants/Emails';
import useAccount from '@/hooks/account/useAccount';
import { AccountModel } from '@/models/Account';
import useFeatureFlags from '@/services/FeatureFlag';
import UserTracking from '@/services/UserTracking';
import { initToken } from '@/utility/api';

import { auth } from '../firebaseSetup';

type AccountContextModel = {
  isAuthenticated: boolean;
  account?: AccountModel;
  accountError: unknown;
  accountIsLoading: boolean;
  refetchAccount: KeyedMutator<AxiosResponse<AccountModel> | null>;
  accountId?: string;
  dispatchFirebaseAccountId: (accountId: string) => Promise<void>;
  logoutUser: () => void;
};

export const AccountContext = createContext<AccountContextModel | undefined>(undefined);

const AccountProvider = ({ children }: { children: ReactNode }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { identifyUser } = useFeatureFlags();

  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [firebaseAccountId, setFirebaseAccountId] = useState<string>();
  const [tokenExpiration, setTokenExpiration] = useState<number>();
  const [accountId, setAccountId] = useState<string>();
  const [hasRedirectedToEmailVerification, setHasRedirectedToEmailVerification] = useState<boolean>(false);
  const { account, accountError, accountIsLoading, refetchAccount } = useAccount({ firebaseAccountId });

  const logoutUser = useCallback(() => {
    if (account) {
      const userTracking = new UserTracking({ account });
      userTracking.userLogout();
    }
    setFirebaseAccountId(undefined);
    setIsAuthenticated(false);
    initToken();
    axios.defaults.headers.common['X-Account-Id'] = undefined;
    signOut(auth);
    const searchParams = location.search;
    navigate(`/sign-up${searchParams}`);
  }, [account, location.search, navigate]);

  const refreshToken = useCallback(async () => {
    const tokenResult = await auth.currentUser?.getIdTokenResult();
    if (!tokenResult) {
      logoutUser();
      return;
    }

    initToken(tokenResult.token);
    setTokenExpiration(new Date(tokenResult.expirationTime).getTime());
  }, [logoutUser]);

  const dispatchFirebaseAccountId = useCallback(
    async (id: string) => {
      await refreshToken();
      setFirebaseAccountId(id);
      await refetchAccount();
      setIsAuthenticated(true);
    },
    [refetchAccount, refreshToken]
  );

  const checkValidToken = useCallback(async () => {
    if (!tokenExpiration) return;
    const currentTime = Date.now();
    const FIVE_MINUTES = 3e5;

    if (tokenExpiration - currentTime < FIVE_MINUTES) {
      await auth.currentUser?.getIdToken(true);
      await refreshToken();
    }
  }, [refreshToken, tokenExpiration]);

  useEffect(() => {
    const interactionHandler = () => {
      if (document.visibilityState === 'visible') {
        checkValidToken();
      }
    };

    document.addEventListener('visibilitychange', interactionHandler);
    document.addEventListener('click', interactionHandler);
    document.addEventListener('keypress', interactionHandler);

    return () => {
      document.removeEventListener('visibilitychange', interactionHandler);
      document.removeEventListener('click', interactionHandler);
      document.removeEventListener('keypress', interactionHandler);
    };
  }, [checkValidToken]);

  const contextValue: AccountContextModel = {
    isAuthenticated,
    account,
    accountError,
    accountIsLoading,
    refetchAccount,
    accountId,
    dispatchFirebaseAccountId,
    logoutUser,
  };

  useEffect(() => {
    if (accountError) {
      if (hasRedirectedToEmailVerification) return;
      if (
        !auth.currentUser?.emailVerified &&
        AllowedUnverifiedEmails.includes(auth.currentUser?.email || '') === false
      ) {
        setHasRedirectedToEmailVerification(true);
        return navigate('/email-verification');
      }
      if (isAxiosError(accountError) && accountError.response?.status === 404) {
        if (location.pathname !== '/onboarding') navigate('/onboarding');
        return;
      }

      logoutUser();
    }
  }, [accountError, hasRedirectedToEmailVerification, location, logoutUser, navigate, setAccountId]);

  useEffect(() => {
    if (!account) return;
    axios.defaults.headers.common['X-Account-Id'] = account.id;
    setAccountId(account.id);
    if (account) {
      const userTracking = new UserTracking({ account });
      userTracking.userLogin();
    }
    identifyUser(account.id);
  }, [account, identifyUser]);

  return <AccountContext.Provider value={contextValue}>{children}</AccountContext.Provider>;
};

export default AccountProvider;
