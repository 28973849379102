import React, { createContext, ReactNode, SetStateAction, useCallback, useEffect, useRef, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';

import CSATSurvey from '@/components/surveys/CSATSurvey';
import { FeatureFlagNameFormatter } from '@/constants/FeatureFlagEvents';
import useSubscription from '@/hooks/account/useSubscription';
import useReleaseCycleTaskCompletionStats from '@/hooks/release-cycles/useReleaseCycleTaskCompletionStats';
import { SurveyQueryParams } from '@/models/Enums';
import { CSATSurveyConfig } from '@/models/Surveys';
import useFeatureFlags from '@/services/FeatureFlag';

import { useLocalStorage } from '../services/LocalStorage';

type SurveyContextModel = {
  survey: unknown | null;
  surveyType: string | null;
  setIsOpen: (value: SetStateAction<boolean>) => void;
  hasInteractedWithSurvey: (id: string) => boolean | undefined;
  setSurveyType: (value: SetStateAction<string | null>) => void;
  setSurvey: (value: SetStateAction<CSATSurveyConfig | null>) => void;
};

export const SurveyContext = createContext<SurveyContextModel | undefined>(undefined);

const SurveyProvider = ({ children }: { children: ReactNode }) => {
  const { isSubscribed, status } = useSubscription();
  const { getItem } = useLocalStorage();
  const [surveyType, setSurveyType] = useState<string | null>('csat');
  const [survey, setSurvey] = useState<CSATSurveyConfig | null>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const location = useLocation();
  const [params, setSearchParams] = useSearchParams();
  const hasCheckedSubscriptionSurvey = useRef(false);
  const { surveys } = useFeatureFlags();

  const { releaseCyclesCompletionStats } = useReleaseCycleTaskCompletionStats({
    scheduleId: '',
  });

  const tasksCompleted = releaseCyclesCompletionStats?.reduce(
    (total, customDate) => total + (customDate.completed || 0),
    0
  );
  const hasInteractedWithSurvey = useCallback(
    (id: string) => {
      if (getItem(`hasInteractedWithSurveyId${id}`)) {
        return true;
      }
    },
    [getItem]
  );

  const contextValue: SurveyContextModel = {
    survey: survey,
    surveyType: surveyType,
    setIsOpen: setIsOpen,
    hasInteractedWithSurvey: hasInteractedWithSurvey,
    setSurveyType: setSurveyType,
    setSurvey: setSurvey,
  };

  const handleReleaseCycleCheckInSurvey = useCallback(() => {
    if (!tasksCompleted || (tasksCompleted && tasksCompleted < 5)) {
      return setIsOpen(false);
    }
    if (surveys) {
      const surveysArray = surveys as CSATSurveyConfig[];
      const releaseCyclesCheckInSurvey = surveysArray.filter(
        (survey) => survey.linked_flag_key === FeatureFlagNameFormatter('ReleaseCycleCSATCheckIn')
      );

      if (releaseCyclesCheckInSurvey.length > 0) {
        const CSATSurvey = releaseCyclesCheckInSurvey[0];
        if (CSATSurvey) {
          if (!hasInteractedWithSurvey(CSATSurvey.id)) {
            setSurvey(CSATSurvey);
            return setIsOpen(true);
          }
        }
      }
    }
  }, [hasInteractedWithSurvey, surveys, tasksCompleted]);

  const handleReleaseCycleEndSurvey = useCallback(() => {
    if (
      location.pathname === '/release-complete' ||
      params.get(SurveyQueryParams.RELEASE_CYCLE_END_SURVEY) === 'true'
    ) {
      if (surveys) {
        const surveysArray = surveys as CSATSurveyConfig[];
        const releaseCyclesEndSurvey = surveysArray.filter(
          (survey) => survey.linked_flag_key === FeatureFlagNameFormatter('ReleaseCycleCSATEnd')
        );

        if (releaseCyclesEndSurvey.length > 0) {
          const CSATSurvey = releaseCyclesEndSurvey[0];
          if (CSATSurvey) {
            if (!hasInteractedWithSurvey(CSATSurvey.id)) {
              setSurvey(CSATSurvey);
              return setIsOpen(true);
            } else {
              params.delete(SurveyQueryParams.RELEASE_CYCLE_END_SURVEY);
              setSearchParams(params);
              setIsOpen(false);
            }
          }
        }
      }
    }
  }, [hasInteractedWithSurvey, location.pathname, params, setSearchParams, surveys]);

  const handleSurvey = useCallback(
    (queryParams: string, flagName: string) => {
      if (params.get(queryParams) === 'true') {
        if (surveys) {
          const surveysArray = surveys as CSATSurveyConfig[];
          const selectedSurvey = surveysArray.filter(
            (survey) => survey.linked_flag_key === FeatureFlagNameFormatter(flagName)
          );

          if (selectedSurvey.length > 0) {
            const CSATSurvey = selectedSurvey[0];
            if (CSATSurvey) {
              if (!hasInteractedWithSurvey(CSATSurvey.id)) {
                setSurvey(CSATSurvey);
                return setIsOpen(true);
              } else {
                params.delete(queryParams);
                setSearchParams(params);
                setIsOpen(false);
              }
            }
          }
        }
      }
    },
    [hasInteractedWithSurvey, params, setSearchParams, surveys]
  );

  useEffect(() => {
    handleReleaseCycleCheckInSurvey();
    handleReleaseCycleEndSurvey();
    handleSurvey(SurveyQueryParams.LYRA_SURVEY, 'LyraCSAT');
    handleSurvey(SurveyQueryParams.PITCHING_SURVEY, 'PlaylistPitchCSAT');
    handleSurvey(SurveyQueryParams.SUBSCRIPTION_SURVEY, 'SubscriptionCSAT');
  }, [handleReleaseCycleCheckInSurvey, handleReleaseCycleEndSurvey, handleSurvey]);

  useEffect(() => {
    if (hasCheckedSubscriptionSurvey.current) return;
    hasCheckedSubscriptionSurvey.current = true;
    if (status !== 'trial' && isSubscribed) {
      const surveysArray = surveys as CSATSurveyConfig[];
      const selectedSurvey = surveysArray?.filter(
        (survey) => survey.linked_flag_key === FeatureFlagNameFormatter('SubscriptionCSAT')
      );
      if (selectedSurvey?.length > 0) {
        const hasReplied = hasInteractedWithSurvey(selectedSurvey[0].id);
        if (!hasReplied) {
          params.set(SurveyQueryParams.SUBSCRIPTION_SURVEY, 'true');
          setSearchParams(params);
        }
      }
    }
  }, [status, isSubscribed, params, setSearchParams, surveys, hasInteractedWithSurvey]);

  return (
    <SurveyContext.Provider value={contextValue}>
      {surveyType === 'csat' && (
        <CSATSurvey
          survey={survey}
          isOpen={isOpen}
          onClose={() => {
            setIsOpen(false);
          }}
        />
      )}
      {children}
    </SurveyContext.Provider>
  );
};

export default SurveyProvider;
