import React, { createContext, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import WalkthroughMarketingPreferences from '@/components/walkthrough/WalkthroughMarketingPreferences';
import useBreakpoints from '@/hooks/utility/useBreakpoints';
import useWalkthroughManager from '@/hooks/walkthrough/useWalkthroughManager';
import { ACTIONS, EVENTS, PromoteFlowQueryParams, VIEW_STATE } from '@/models/Enums';

import { WalkthroughModal, WalkthroughModalProps } from '../components/utility/modals/WalkthroughModal';
import WalkthroughComponent from '../components/walkthrough/WalkthroughComponent';
import { CallBackProps, ViewState } from '../models/Walkthrough';

export type WalkthroughContextModel = {
  viewState: ViewState | null;
  setViewState: (state: ViewState) => void;
  endWalkthrough: () => void;
};

export const WalkthroughContext = createContext<WalkthroughContextModel | undefined>(undefined);

type WalkthroughProviderProps = {
  children: ReactNode;
};

export const WalkthroughProvider: React.FC<WalkthroughProviderProps> = ({ children }) => {
  const [isRunning, setIsRunning] = useState<boolean>(false);
  const { breakpointHit } = useBreakpoints();
  const [stepIndex, setStepIndex] = useState<number>(0);
  const [dialogState, setDialogState] = useState<'none' | 'start' | 'end'>('none');
  const [walkthroughEnded, setWalkthroughEnded] = useState<boolean>(false);
  const { walkthrough, viewState, setViewState } = useWalkthroughManager();
  const [params, setSearchParams] = useSearchParams();

  const startWalkthrough = useCallback(
    (modelViewed?: boolean) => {
      setWalkthroughEnded(false);
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
      if (walkthrough?.startModal && !modelViewed) {
        setDialogState('start');
        setIsRunning(false);
      } else {
        setDialogState('none');
        setIsRunning(true);
      }
    },
    [setDialogState, setIsRunning, walkthrough?.startModal]
  );

  const skipWalkthrough = useCallback(() => {
    setDialogState('none');
    setIsRunning(false);
    setViewState(VIEW_STATE.SKIPPED);
    setStepIndex(0);
  }, [setViewState]);

  const endWalkthrough = useCallback(() => {
    setDialogState('none');
    setIsRunning(false);
    setViewState(VIEW_STATE.VIEWED);
    setStepIndex(0);
    setWalkthroughEnded(true);
  }, [setViewState]);

  useEffect(() => {
    if (walkthrough) {
      if (!viewState || viewState !== VIEW_STATE.UNSEEN) {
        setDialogState('none');
        setIsRunning(false);
        setStepIndex(0);
        return;
      }

      startWalkthrough();
    }
  }, [walkthrough, viewState, startWalkthrough]);

  const handleCallback = async (data: CallBackProps) => {
    const { action, status, event } = data;

    if (walkthrough?.id === 'marketingAssistant' && status !== 'skipped') {
      params.set(PromoteFlowQueryParams.BASIC_INFO, 'true');
      setSearchParams(params);
      return endWalkthrough();
    }

    const id = (data.step.target as string).replace('#', '');
    const element = document.getElementById(id);
    const startElements = ['release-cycle-calender', 'home-screen-release-cycles'];
    const halfwayElements = ['home-page-widgets'];
    if (element) {
      if (!breakpointHit) {
        element.scrollIntoView({ behavior: 'smooth', block: 'center' });
      } else {
        if (startElements.includes(id)) {
          element.scrollIntoView({ behavior: 'smooth', block: 'start' });
        } else if (halfwayElements.includes(id)) {
          window.scrollTo({
            top: 200,
            left: 0,
            behavior: 'smooth',
          });
        } else {
          element.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
      }
    }

    if (event === EVENTS.TARGET_NOT_FOUND) {
      setStepIndex((prev) => prev + (action === ACTIONS.PREV ? -1 : 1));
    }

    if (event === EVENTS.STEP_AFTER) {
      setStepIndex((prev) => prev + (action === ACTIONS.PREV ? -1 : 1));
    }

    if (status === 'skipped') {
      return skipWalkthrough();
    }

    if (status === 'finished' && walkthrough) {
      if (walkthrough.endModal) {
        setIsRunning(false);
        setDialogState('end');
        return;
      } else {
        endWalkthrough();
      }
    }
  };

  const configureModal = (
    modal: WalkthroughModalProps | undefined,
    onNextAction: () => void,
    onCloseAction: () => void,
    onSkipAction: () => void
  ) => {
    if (!modal) return null;

    return {
      ...modal,
      onNext: () => {
        modal?.onNext?.();
        onNextAction();
      },
      onClose: () => {
        modal?.onClose?.();
        onCloseAction();
      },
      onSkip: () => {
        modal?.onSkip?.();
        onSkipAction();
      },
    };
  };

  const walkthroughStartModalInner = useMemo(
    () => configureModal(walkthrough?.startModal, () => startWalkthrough(true), skipWalkthrough, skipWalkthrough),
    [walkthrough, startWalkthrough, skipWalkthrough]
  );

  const walkthroughEndModalInner = useMemo(
    () => configureModal(walkthrough?.endModal, endWalkthrough, skipWalkthrough, skipWalkthrough),
    [walkthrough, skipWalkthrough, endWalkthrough]
  );

  return (
    <WalkthroughContext.Provider value={{ endWalkthrough, viewState, setViewState }}>
      {!isRunning && dialogState === 'none' && (
        <WalkthroughMarketingPreferences
          walkthroughEnded={walkthroughEnded}
          setWalkthroughEnded={() => {
            endWalkthrough();
          }}
        />
      )}
      {walkthrough && (
        <>
          {!!walkthroughStartModalInner && (
            <WalkthroughModal
              {...(walkthroughStartModalInner as WalkthroughModalProps)}
              isOpen={dialogState === 'start'}
            />
          )}
          {!!walkthroughEndModalInner && (
            <WalkthroughModal {...(walkthroughEndModalInner as WalkthroughModalProps)} isOpen={dialogState === 'end'} />
          )}
          <WalkthroughComponent
            walkthrough={walkthrough}
            stepIndex={stepIndex}
            run={isRunning}
            callback={handleCallback}
          />
        </>
      )}
      {children}
    </WalkthroughContext.Provider>
  );
};
