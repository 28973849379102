import React, { createContext, ReactNode, SetStateAction, useEffect, useState } from 'react';
import Lottie from 'react-lottie-player';

import confettiAnimation from '../assets/animations/Confetti.json';

type AnimationContextModel = {
  animation: string | null;
  fireAnimation: (value: SetStateAction<string | null>) => void;
};

export const AnimationContext = createContext<AnimationContextModel | undefined>(undefined);

const AnimationProvider = ({ children }: { children: ReactNode }) => {
  const [animation, setAnimation] = useState<string | null>(null);

  useEffect(() => {
    if (animation) {
      setTimeout(() => {
        setAnimation(null);
      }, 3500);
    }
  }, [animation]);

  const contextValue: AnimationContextModel = {
    animation: animation,
    fireAnimation: setAnimation,
  };

  return (
    <AnimationContext.Provider value={contextValue}>
      {animation === 'confetti' && (
        <Lottie animationData={confettiAnimation} play loop className="pos-fix z-1000 w100vw h100vh t0" />
      )}
      {children}
    </AnimationContext.Provider>
  );
};

export default AnimationProvider;
